<script>
import BoardResumen from '@/components/Lego/BoardResumen.vue'
import AddBtn from '@/components/buttons/AddBtn.vue'
import LegoBoards from '@/components/Lego/LegoBoards.vue'

export default {
  components: {
    BoardResumen,
    AddBtn,
    LegoBoards
  },
  async mounted() {
    await this.$store.dispatch('lego/getAndSetBoards')
  },
  computed: {
    boards() {
      return this.$store.state.lego.boards
    },
    boardsFilter() {
      if (this.inTableros) {
        return this.boards.filter(b => !b.isTemplate)
      } else {
        return this.boards.filter(b => b.isTemplate)
      }
    },
    onlyBoards() {
      return this.boards.filter(b => !b.isTemplate)
    },
    onlyTemplates() {
      return this.boards.filter(b => b.isTemplate)
    }

  },
  data() {
    return {
      inTableros: true
    }
  },
}

</script>

<template>
  <div class="inicio">
    <router-view />
    <header class="header">
      <span class="title">LEGOSIS</span>
    </header>

    <div class="secciones">
      <LegoBoards :boards="onlyBoards" title="Tus tableros" hiddenSelect>
        <template #defaultBoard>
          <BoardResumen class="newBoard">
            <div class="newBoard">
              <AddBtn />
              <span>Crea un tablero</span>
            </div>
          </BoardResumen>
        </template>
      </LegoBoards>
      <LegoBoards :boards="onlyTemplates" title="Plantillas" />

      <!-- se puede agregar link de videos,  -->
      <!-- to do list, notas, donde es responsables  -->
      <!-- analítica, cuantas se han terminado tareas o checklist, -->
    </div>

  </div>
</template>

<style scoped>
.inicio {
  padding: 1rem;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
}

.secciones {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.header {
  margin-bottom: 1rem;
}

.header .title {
  font-size: 2rem;
  letter-spacing: .25rem;
}

.newBoard {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
}

.newBoard span {
  font-size: 1.1rem;
  color: var(--lego-gris);
  transition: 0.3s;
}

.newBoard :deep(.feather) {
  stroke: var(--black) !important;
  transition: 0.3s;
}

.newBoard:hover :deep(.feather), .newBoard:hover span {
  stroke: var(--black) !important;
  color: var(--black) !important;
}

</style>